<template>
  <section id="coming-soon" class="px-lg-15 mx-lg-15 py-lg-8">
    <card-widget>
      <template #cardBody>
        <div id="coming-soon__content">
          <v-img
            contain
            id="coming-soon__image"
            class="pb-4"
            :aspect-ratio="28 / 8"
            :src="$images.comingSoon"
          />
          <v-row no-gutters>
            <v-col cols="12" md="12" class="d-flex justify-center text-center">
              <div>
                <h1 class="text-h5 font-weight-large text--primary">
                  {{ $dictionary.app.comingSoon.title }}
                </h1>
                <div class="pt-2 text-center">
                  <span class="text--secondary subtitle-1">
                    {{ subTitle }}
                  </span>
                </div>
                <div id="dashboard-button" class="pt-6">
                  <v-btn
                    link
                    type="button"
                    color="primary"
                    :to="$paths.dashboard"
                    class="button font-weight-bold"
                  >
                    {{ $dictionary.app.comingSoon.btnText }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </card-widget>
  </section>
</template>

<script>
import { CardWidget } from "@/components/widgets";

const TEXT_TO_REPLACE = "_";
/**
 * Coming soon view
 */
export default {
  name: "ComingSoon",
  /**
   * ---------------- Components ------------------
   */
  components: { CardWidget },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * Computes the subtitle of the page on the basis of the route name
     * @type {String}
     */
    subTitle() {
      const { capitalize } = this.$options.filters;
      const { name } = this.$route;
      let { subtitle } = this.$dictionary.app.comingSoon;

      let title = name.includes("-") ? name.replace("-", " ") : name;
      title = title
        .split(" ")
        .map((str) => capitalize(str))
        .join(" ");

      return subtitle.replace(TEXT_TO_REPLACE, title);
    },
  },
};
</script>
